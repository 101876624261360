.login {
  background-color: #080710;
}
.login .background {
  width: 430px;
  height: 520px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.login .background .shape {
  height: 200px;
  width: 200px;
  position: absolute;
  border-radius: 50%;
}
.login .shape:first-child {
  background: linear-gradient(#1845ad, #23a2f6);
  left: -80px;
  top: -80px;
}
.login .shape:last-child {
  background: linear-gradient(to right, #ff512f, #f09819);
  right: -30px;
  bottom: -80px;
}
.login form {
  height: 520px;
  width: 400px;
  background-color: rgba(255, 255, 255, 0.13);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
  padding: 50px 35px;
}
.login form * {
  font-family: "Poppins", sans-serif;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}
.login form h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  text-align: center;
}

.login label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}
.login input {
  border: 1px solid #2089e2;
  display: block;
  height: 50px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.07);
  border-radius: 3px;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

.login input::placeholder {
  color: #000000;
}
.login button {
  margin-top: 50px;
  width: 100%;
  /* background-color: #ffffff; */
  /* color: #080710; */
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.login .social {
  margin-top: 30px;
  display: flex;
}
.login .social div {
  background: red;
  width: 150px;
  border-radius: 3px;
  padding: 5px 10px 10px 5px;
  background-color: rgba(255, 255, 255, 0.27);
  color: #eaf0fb;
  text-align: center;
}
.login .social div:hover {
  background-color: rgba(255, 255, 255, 0.47);
}
.login .social .fb {
  margin-left: 25px;
}
.login .social i {
  margin-right: 4px;
}
